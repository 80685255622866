<template>
  <div>
    <overlay
      v-show="shouldOpen && !areAllComponentsMounted"
    >
      <credpal-loader />
    </overlay>

    <AccountBlacklisted
      ref="accountBlacklisted"
      @complete-account-verification="nextStep"
      @mounted="registerMountedComponent('accountBlacklisted')"
    />
    <AccountDeactivated
      ref="accountDeactivated"
      @complete-account-verification="nextStep"
      @mounted="registerMountedComponent('accountDeactivated')"
    />
    <IdentityVerification
      ref="identityVerification"
      @complete-identity-verification="nextStep"
      @mounted="registerMountedComponent('identityVerification')"
    />
    <AccountVerification
      ref="accountVerification"
      @complete-account-verification="nextStep"
      @mounted="registerMountedComponent('accountVerification')"
    />
    <Form2
      ref="form2"
      @complete-form2="nextStep"
      @mounted="registerMountedComponent('form2')"
    />
    <NinVerification
      ref="ninVerification"
      @complete-nin-verification="nextStep"
      @mounted="registerMountedComponent('ninVerification')"
    />
    <InstantApproval
      ref="instantApproval"
      @complete-instant-verification="nextStep"
      @mounted="registerMountedComponent('instantApproval')"
    />
    <Documents
      ref="documents"
      @upload-documents="nextStep"
      @mounted="registerMountedComponent('documents')"
    />
    <AwaitingApproval
      ref="awaitingApproval"
      @mounted="registerMountedComponent('awaitingApproval')"
    />
    <CardRequest
      ref="cardRequest"
      @mounted="registerMountedComponent('cardRequest')"
      @request-card="nextStep"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserOnboarding',
  components: {
    AccountBlacklisted: () => import('./account-blacklisted/Index.vue'),
    AccountDeactivated: () => import('./account-deactivated/Index.vue'),
    AccountVerification: () => import('./account-verification/Index.vue'),
    AwaitingApproval: () => import('./awaiting-approval/Index.vue'),
    CardRequest: () => import('./card-request/Index'),
    Documents: () => import('./documents/Index'),
    Form2: () => import('./form2/Index'),
    IdentityVerification: () => import('./identity-verification/Index.vue'),
    InstantApproval: () => import('./instant-approval/Index.vue'),
    NinVerification: () => import('./nin-verification/Index.vue'),
  },
  data() {
    return {
      mountedComponents: {
        accountBlacklisted: false,
        accountDeactivated: false,
        accountVerification: false,
        awaitingApproval: false,
        cardRequest: false,
        documents: false,
        form2: false,
        identityVerification: false,
        instantApproval: false,
        ninVerification: false,
      },
      shouldOpen: false,
    }
  },
  computed: {
    areAllComponentsMounted() {
      return Object.keys(this.mountedComponents).every((key) => {
        return !!this.mountedComponents[key];
      });
    },
  },
  mounted() {
    this.$root.$on('open-onboarding', this.open);

    this.showWelcomeAlert();
    this.showPopUp();
  },
  methods: {
    ...mapActions('session', ['updateUser']),
    continueOnboarding(isContinuousFlow = false) {
      if (this.validateUser('blacklisted')) {
        return this.$refs.accountBlacklisted.open();
      }

      if (this.validateUser('declined')) {
        return this.$refs.accountDeactivated.open();
      }

      if (!this.validateUser('bvn') || !this.validateUser('date_of_birth')) {
        return this.$refs.identityVerification.open();
      }

      if (this.validateUser('onboarding.instant_approval_qualification') && !this.validateUser('onboarding.instant_approval')) {
        return this.$refs.instantApproval.open();
      }

      if (!this.validateUser('onboarding.account_verification')) {
        return this.$refs.accountVerification.open();
      }

      if (!this.validateUser('onboarding.form2')) {
        return this.$refs.form2.open();
      }
      
      if (!this.validateUser('identity')) {
        return this.$refs.ninVerification.open();
      }
      
      if ((!this.validateUser('govt_id') || !this.validateUser('work_id')) && !this.validateUser('approval')) {
        return this.$refs.documents.open();
      }

      if (isContinuousFlow && !this.validateUser('approval')) {
        return this.$success({
          title: 'Profile Updated Successfully',
          body:
            'Your account is under review and will be active once approved.',
          button: 'okay'
        });
      }

      if (!this.validateUser('approval')) {
        return this.$refs.awaitingApproval.open();
      }

      if (!this.validateUser('card_request')) {
        return this.$refs.cardRequest.open();
      }
    },
    open() {
      this.shouldOpen = true;
      if (!this.areAllComponentsMounted) {
        return;
      }
      this.continueOnboarding();
    },
    registerMountedComponent(componentName) {
      this.mountedComponents[componentName] = true;

      this.$nextTick(() => {
        if (this.areAllComponentsMounted) {
          this.$emit('mounted');
          if (this.shouldOpen) {
            this.open();
          }
        }
      });
    },
    showPopUp() {
      const canRequestForCard = (
        this.validateUser('onboarding.form2')
        && this.validateUser('approval')
        && !this.validateUser('card_request')
      );
      const isDeclined = this.validateUser('declined');
      const isBlacklisted = this.validateUser('blacklisted');
      if (canRequestForCard || isDeclined || isBlacklisted) {
        this.open();
      }
    },
    showWelcomeAlert() {
      const isBrandNew = this.validateUser('onboarding.brand_new');
      const canOpenWelcomeAlert = !this.isModalClosed('user-welcome');
      if (isBrandNew && canOpenWelcomeAlert) {
        return this.$success({
          title: 'Welcome',
          body: `Hello ${this.firstName}, your account was successfully created.`,
          subBody: `Kindly verify your account to continue.`,
          button: 'Continue',
          onClose: () => {
            this.closeModal('user-welcome');
          }
        });
      }
    },
    nextStep() {
      this.$nextTick(() => {
        this.continueOnboarding();
      });
    },
    saveAccountVerificationData({ user }) {
      this.updateUser(user);
      this.$nextTick(() => {
        this.continueOnboarding(true);
      });
    },
  }
};
</script>
